@import 'utils';

.main {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'sidebar';
  row-gap: spacing(2);

  @include up(desktop) {
    column-gap: spacing(4);
    grid-template-areas:
      'header header sidebar'
      'main main sidebar';
    grid-template-rows: auto;
  }
}

.header {
  display: grid;

  grid-template-areas:
    'title'
    'reports'
    'subtitle';
  row-gap: 15px;
  * {
    margin-bottom: 0;
  }

  @include up(tablet) {
    column-gap: spacing(6);
    grid-area: header;
    grid-template-areas:
      'title image'
      'subtitle image'
      'reports reports';
    row-gap: 10px;
  }
}

.title {
  grid-area: title;
  @include up(desktop) {
    line-height: 57px;
  }
}

.title_subtext {
  grid-area: subtitle;

  p {
    margin-bottom: 0px;
  }
}

.title_image {
  display: none;
  grid-area: image;
  @include up(tablet) {
    height: 200px;
    position: relative;
    @include flex-row;
    width: 250px;
  }
}

.title_image_background_rounded {
  background-color: var(--color-brand-gartner-blue);
  border-radius: 50% 0 0 50%;
  height: 200px;
  width: 200px;
}

.title_image_background {
  background-color: var(--color-brand-gartner-blue);
  height: 200px;
  width: 50px;
}

.image_container {
  height: 80%;
  left: 10%;
  position: absolute;
  top: 10%;
  width: 80%;

  img {
    object-fit: contain;
  }
}

.section {
  background-color: var(--color-polar);
  grid-area: reports;

  p {
    margin-bottom: 0;
  }

  @include up(tablet) {
    margin-top: 5px;
  }
}

.content {
  background-color: var(--color-polar);
  margin-top: spacing(2);
  padding: spacing(6) spacing(5);

  .main_rail {
    background-color: var(--color-background-hex);
    padding: 0 spacing(6);
  }

  @include up(desktop) {
    grid-area: main;
  }
}

.sidebar {
  @include flex-column;
  gap: spacing(5);
  grid-area: sidebar;
  @include up(desktop) {
    max-width: 371px;
  }
}

.join {
  display: none;
  @include up(desktop) {
    @include flex-column;
  }
}

.more_like_this {
  margin-top: spacing(5);
  @include up(desktop) {
    margin-top: 0;
  }
}

.related_omis {
  @include flex-column;
  background-color: var(--color-polar);
  gap: spacing(4);
  padding: spacing(4);

  @include up(tablet) {
    @include flex-row;
  }

  @include up(desktop) {
    @include flex-column;
  }
}

.omi_card {
  width: 100%;
  @include up(tablet) {
    width: 33%;
  }
  @include up(desktop) {
    width: 100%;
  }
}

.footer {
  margin: 0 auto;
  text-align: center;
  @include up(desktop) {
    max-width: 720px;
  }

  .disclaimer {
    margin-bottom: spacing(4);
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}
