@import 'utils';

.topics_list {
  @include flex-column;
  flex: 1;
  overflow: hidden;

  .topics_search_input {
    appearance: none;
    border: 1px solid var(--color-foreground-hex);
    box-sizing: border-box;
    margin-bottom: spacing(4);
    padding: spacing(3);
    resize: none;
  }

  .topics {
    overflow-y: auto;
    @include flex-column;

    > :not(:last-child) {
      border-bottom: 1px solid var(--color-foreground-hex);
    }

    .topic {
      .topic_main {
        align-items: center;
        display: flex;
        justify-content: space-between;
        min-height: 50px;
        padding: 0px 16px;

        .topic_checkbox_label {
          white-space: nowrap;
        }

        .topic_info {
          display: flex;
          gap: spacing(3);
        }

        .topic_open {
          display: flex;
          justify-content: flex-end;
          min-height: 50px;
          width: 100%;
        }
      }

      .sub_topics {
        display: grid;
        grid-template-columns: auto;
        justify-items: flex-start;
        padding: 0px 0px spacing(4) spacing(8);
        row-gap: spacing(6);

        @include up(tablet) {
          column-gap: spacing(4);
          grid-template-columns: 50% 50%;
        }
      }
    }
  }
}
