@import 'utils';

.highlights {
  @include flex-column;
  color: var(--color-product-text);
  gap: spacing(6);

  .community {
    > :not(:last-child) {
      margin-bottom: spacing(6);
    }
  }

  @include up(tablet) {
    @include flex-row;
    gap: 60px;
    justify-content: space-between;
    padding: 60px;
    .community {
      flex: 1.25;
    }
    .highlight_cards {
      flex: 1;
    }
  }

  .highlight_cards {
    display: block;

    .card {
      @include flex-row;
      padding: spacing(6);
      text-align: left;

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-neutral-500);
        margin-bottom: 0;
      }
    }
  }
}
