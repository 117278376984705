@import 'utils';

.pagination {
  align-items: center;
  background-color: var(--color-background-hex);
  display: flex;
  gap: spacing(9);
  height: 40px;
  justify-content: flex-end;
  width: 100%;

  .count {
    display: none;
  }

  .numbers {
    color: var(--button-primary-bg-color);
    cursor: pointer;
    display: flex;
    gap: spacing(1);

    & > * {
      height: 24px;
      line-height: 24px;
      min-width: 24px;
      text-align: center;

      &:hover:not(.separator) {
        background-color: var(--button-primary-bg-color);
        color: var(--color-white);
      }
    }

    .active {
      background-color: var(--button-primary-bg-color);
      color: var(--color-white);
    }
  }
}

@include up(tablet) {
  .pagination {
    .count {
      color: var(--color-product-text);
      display: inline;
    }
  }
}
