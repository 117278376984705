@import 'utils';

.main {
  margin: spacing(10) auto;
  max-width: 1440px;
  padding: 0 spacing(5);
  @include up(tablet) {
    padding: 0 spacing(15);
  }
}

.title {
  margin: spacing(5) 0 10px 0;
  padding-bottom: spacing(5);
}

.alphabet {
  border-bottom: 1px solid var(--color-neutral-400);
  border-top: 1px solid var(--color-neutral-400);
  margin-bottom: spacing(10);
  padding: 10px 0 2px 0;
}

.pagination {
  border-top: 1px solid var(--color-neutral-400);
  margin-top: spacing(10);
  padding-top: 10px;
}
