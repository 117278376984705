@import 'utils';

.related_topics {
  @include flex-column;
  gap: spacing(2);
}

.tags {
  @include flex-row;
  flex-wrap: wrap;
  gap: spacing(2);
}
