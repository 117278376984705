@import 'utils';

.listing_profiles {
  display: grid;
  gap: spacing(4);
  grid-template-columns: 1fr 1fr;
  list-style: none;

  @include up(tablet) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 30px 0;
  }

  @include up(desktop) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  a {
    color: var(--color-brand-gartner-blue);

    &:hover {
      text-decoration: underline;
    }
  }
}
