@import 'utils';

.card_header {
  align-self: center;
  display: flex;
  grid-area: header;
  justify-content: space-between;
  margin-block: spacing(6) spacing(5);
  > div{
    margin-left: auto;
  }
}


.flag_content {
  background-color: var(--color-background-hex);
  box-shadow: var(--box-shadow-medium);
  color: var(--color-foreground-hex);
  width: 250px;
  z-index: 5;
  button {
    all: unset;
  }
}

.allowed_contributors {
  display: flex;
  gap: spacing(1);
}

.allowed_contributors .tooltip svg {
  @include down(tablet) {
    display: none;
  }
}
