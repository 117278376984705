@import 'utils';

.create_post_poll_form {
  @include flex-column;
  gap: spacing(6);

  textarea {
    resize: none;
  }
}

.departments {
  @include flex-column;
  align-items: flex-start;
  gap: spacing(4);
  @include up(tablet) {
    @include flex-row;
    align-items: center;
  }
  b {
    font-weight: 500;
  }
}

.poll_choices {
  color: var(--color-brand-gartner-blue);
}

.radio_section {
  display: flex;
  flex-wrap: wrap;
}

.submit {
  align-items: center;
  display: flex;
  gap: spacing(3);
}

.preview_card {
  border: 1px solid var(--color-neutral-400);
  max-width: 100%;
  display: flex;
  border-radius: 3px;
  &:hover {
    background-color: var(--color-neutral-400);
    border-color: var(--color-neutral-500);
  }
  .image {
    border-radius: 3px 0 0 3px;
  }
  .info {
    display: flex;
    flex-direction: column;
    padding: 6px;
    flex: 1 1 0;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    & > * {
      font-weight: bold;
      line-height: 18px;
      height: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      overflow-wrap: normal;
      white-space: nowrap;
      width: 100%;
    }
    .domain {
      font-size: 13.5px;
      color: var(--color-neutral-700);
    }
    .title {
      font-size: 13.5px;
      color: var(--color-utility-interaction-200);
    }
    .description {
      font-size: 11.5px;
      font-weight: unset;
    }
  }
}
