@import 'utils';

.related_topics {
  @include flex-column;

  .title {
    margin-bottom: spacing(6);
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: spacing(3);
    @include up(tablet) {
      gap: spacing(4);
    }
  }
}

.view_all {
  align-self: center;
  color: var(--color-utility-interaction-500);
  font-weight: 500;
  margin-top: spacing(10);
  width: fit-content;
}
