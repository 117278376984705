@import 'utils';

.community {
  background-color: var(--color-cultured-white);
  padding: spacing(6);
  > :not(:last-child) {
    margin-bottom: spacing(6);
  }
  .active_now {
    text-transform: uppercase;
    font-weight: 500;
    &::before {
      content: '';
      height: 12px;
      width: 12px;
      background-color: var(--color-brand-surf);
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }
  }
  .strong {
    font-size: 48px;
    font-weight: 600;
  }

  .count {
    @include flex-row;
    align-items: center;
    gap: spacing(6);
  }
}
