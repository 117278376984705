@import 'utils';

.search.toggle_search {
  padding: spacing(2);
  color: var(--color-brand-gartner-blue);

  @include up(phone) {
    display: none;
  }
}
