.header {
  cursor: pointer;
}

.content {
  display: none;

  &.true {
    display: block;
  }
}
