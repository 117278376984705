@import 'utils';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: spacing(4);
  gap: spacing(3);

  .date_sent {
    border-bottom: 1px solid var(--color-neutral-400);
    padding: spacing(1) 0 spacing(3);
    margin: 0 spacing(2);
    font-weight: 500;
  }

  .content {
    display: flex;
    gap: spacing(3);
    flex-wrap: wrap;

    .connection_info {
      margin-left: auto;
      min-width: fit-content;
      align-self: center;
    }
  }
}

@include up(tablet) {
  .container {
    gap: spacing(3);
    padding: spacing(3) spacing(6) spacing(6) spacing(6);

    .date_sent {
      padding-top: 0;
    }

    .content {
      flex-wrap: nowrap;
      padding: spacing(3);
    }
  }
}
