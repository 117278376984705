
@import 'utils';

.activity {
  @include flex-row;
  gap: spacing(3);
  color: var(--color-product-text);
  svg {
    display: block;
  }
  .activity_profile {
    margin-bottom: spacing(1);
  }
  .avatar.avatar_small {
    flex-shrink: 0;
    height: 40px;
    width: 40px;
    border-width: 2px;
    span {
      font-size: 24px;
    }
  }
}