@import 'utils';

.root {
  background: var(--color-cultured-white);
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  max-width: 1280px !important;
  padding: 0 !important;

  @include up(tablet) {
    flex-wrap: nowrap;
  }
}

.image {
  width: 100%;

  img {
    display: block;
    height: auto;
    margin: 0 0 0 auto;
    width: 100%;
  }

  @include up(tablet) {
    margin: spacing(10) 0 spacing(10) auto;
    order: 1;
    width: 50%;

    img {
      max-width: 580px;
    }
  }
}

.text {
  margin: auto;
  padding: spacing(5);

  button {
    margin-top: spacing(5);
  }

  @include up(tablet) {
    background: var(--color-background-hex);
    border-left: 16px solid var(--color-brand-tangerine);
    border-right: 16px solid var(--color-brand-tangerine);
    margin: spacing(10) 0;
    padding: 34px spacing(10);
    width: 50%;
  }

  @include up(desktop) {
    margin: auto;
    padding: 52px 60px;
  }
}

.heading {
  strong {
    font-weight: 500;
  }
}
