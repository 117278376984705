@import 'utils';

.wrapper {
  &:not(:last-child) {
    margin-bottom: spacing(8);
  }
}

.title {
  padding-bottom: spacing(3);
}

.products {
  @include up(tablet) {
    display: flex;
  }
}

.cta {
  color: var(--color-brand-gartner-blue);
  margin: auto 0 auto auto;
  text-align: right;
}
