@import 'utils';

.checkbox_with_label {
  @include flex-row;
  align-items: center;
  gap: spacing(4);

  label:hover {
    cursor: pointer;
  }
}
