@import 'utils';

.card {
  padding: spacing(6);
  background-color: var(--neutrals-01-white);
  box-shadow: var(--box-shadow-01-normal);
  border-radius: 4px;
  &:hover {
    box-shadow: var(--box-shadow-01-raised);
  }
  transition: box-shadow 0.3s ease-in-out;
  .title {
    margin-bottom: spacing(4);
  }
}
