@import 'utils';

.testimonials {
  .title {
    margin-bottom: spacing(8);
    text-align: center;
  }

  .testimonials_content {
    overflow: hidden;

    .testimonials_wrapper {
      display: grid;
      grid-template-columns: repeat(3, 100%);
      transition: transform 0.3s;
      @include up(desktop) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .view_all {
    color: var(--color-utility-interaction-500);
    display: block;
    font-weight: 500;
    margin: 30px auto 0 auto;
    text-align: center;
  }
}
