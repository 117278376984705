@import 'utils';

.highlights {
  .header {
    margin-bottom: spacing(12);

    .title {
      margin-bottom: spacing(3);
    }

    .subtitle {
      display: none;
    }
  }

  .highlight_cards {
    margin: 0 auto;
  }

  @include up(desktop) {
    .header {
      text-align: center;

      .subtitle {
        display: block;
      }
    }
  }
}
