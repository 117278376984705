@import 'utils';

.input {
  border: 1px solid var(--color-neutral-600);
  border-radius: 0;
  font-size: 14px;
  height: min-content;
  line-height: 19px;

  padding: 6px 6px;

  width: 100%;
  &:focus {
    outline: none;
  }
}
