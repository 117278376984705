@import 'utils';

.title {
  margin-bottom: spacing(10);
  @include up(desktop) {
    font-size: 45px;
    line-height: 1.25;
  }
}

.content {
  @include flex-column;
  gap: spacing(10);
  margin-bottom: 0;
  @include up(tablet) {
    margin-bottom: spacing(20);
  }
}
