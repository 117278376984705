@import 'utils';

.container {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  width: 100%;
}

.label {
  color: var(--color-brand-gartner-blue);
  margin-right: spacing(2);
}

.optional {
  color: var(--color-brand-gartner-blue);
  font-style: italic;
}
