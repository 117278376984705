@import 'utils';

.grid {
  column-gap: spacing(15);
  columns: 1;

  @include up(tablet) {
    columns: 2;
  }
  @include up(desktop) {
    columns: 3;
  }

  a {
    color: var(--color-brand-gartner-blue);
  }

  h2 {
    margin-bottom: spacing(4);
  }
}

.item {
  break-inside: avoid;
  margin-bottom: spacing(15);
}
