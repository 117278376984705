@import 'utils';

.container {
  & > section,
  & > div > section {
    margin: 0 auto;
    max-width: 1440px;
    padding: spacing(9) spacing(5);
    width: 100%;
  }

  .banner_msg {
    color: var(--color-product-text);
    line-height: 130%;
  }

  .wrapper {
    display: flex;
    padding: spacing(5);

    .side_big {
      display: flex;
      flex-direction: column;
      gap: spacing(3);
    }

    .side_small {
      display: none;
    }

    &.wrapper_benefits {
      display: flex;
      flex-direction: column;
      gap: spacing(4);
      padding: 0 spacing(5);

      .info_card {
        background: var(--neutrals-01-white);
        box-shadow: var(--box-shadow-01-normal);
        display: flex;
        flex-direction: column;
        gap: spacing(4);
        margin: 0 auto;
        max-width: 400px;
        min-height: 235px;
        padding: spacing(5);
        transition: box-shadow 0.3s ease-in-out,
        transform 0.3s ease-in-out;

        &:hover {
          box-shadow: var(--box-shadow-01-raised);
          transform: scale(1.025, 1.025);
        }

        .info_card_icon {
          color: var(--color-picton-blue);
        }

        .info_card_title {
          font-size: 20px;
          line-height: 32px;
        }

        .info_card_text {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &.wrapper_expect {
      display: flex;
      flex-direction: column;
      gap: spacing(4);

      .expect_left {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: spacing(4);

        .expect_left_title {
          font-size: 20px;
          line-height: 32px;
        }
      }

      .expect_right {
        margin-left: spacing(5);

        li {
          font-size: 14px;
          line-height: 20px;
          padding-bottom: spacing(3);
        }
      }
    }
  }

  .row_with_bg {
    background: var(--color-cultured-white);
    padding: spacing(5) 0;

    .benefits_title,
    .ambassadors_title {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: spacing(6);
      text-align: center;
    }
  }

  .hide_mobile {
    display: none;
  }
}

@include up(tablet) {
  .container {
    & > section,
    & > div > section {
      padding: spacing(15);
    }

    .banner_msg {
      font-size: 29px;
    }

    .wrapper {
      gap: 6%;
      padding: spacing(8);

      .side_big {
        display: flex;
        flex-direction: column;
        gap: spacing(6);
        width: 47%;
      }

      .side_small {
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: center;
        width: 47%;

        .bg_img {
          display: block;
          height: auto;
          max-width: 450px;
          width: 100%;
        }
      }

      &.wrapper_expect {
        flex-direction: row;

        .expect_left {
          align-items: flex-start;
          min-width: 250px;

          .expect_left_title {
            font-size: 30px;
            line-height: 44px; /* 146.667% */
          }
        }

        .expect_right {
          li {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    .row_with_bg {
      padding: 34px 0;
    }

    .hide_mobile {
      display: inline;
    }
  }
}

@include up(desktop) {
  .container {
    .banner_msg {
      font-size: 29px;

      & > div {
        gap: spacing(4);
      }
    }

    .wrapper {
      margin: 0 auto;
      max-width: 1250px;
      padding: 60px;

      .side_big {
        display: flex;
        flex-direction: column;
        gap: spacing(4);
        height: 300px;
        max-width: 570px;
        width: 54%;
      }

      .side_small {
        width: 40%;
      }

      &.wrapper_benefits {
        display: flex;
        flex-direction: row;
        gap: 3%;
        padding: 0 24px;

        .info_card {
          flex: 1 1;
          max-width: 400px;
          padding: 24px;
          width: 0;

          .info_card_title {
            font-size: 24px;
            line-height: 36px;
          }

          .info_card_text {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      &.wrapper_expect {
        gap: 80px;
        max-width: 1100px;

        .expect_left {
          min-width: 275px;

          .expect_left_title {
            font-size: 37px;
            line-height: 54px;
          }
        }
      }
    }

    .row_with_bg {
      padding: 60px 0;

      .benefits_title,
      .ambassadors_title {
        font-size: 37px;
        line-height: 54px;
      }
    }
  }
}
