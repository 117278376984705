@import 'utils';

.main {
  padding: 0 spacing(3);
  margin: spacing(10) auto 0;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  gap: spacing(3);
  height: 100vh;
  justify-content: space-between;
  @include up(tablet) {
    margin: spacing(10) auto 0;
  }

  footer {
    margin: 0;
  }

  .breadcrumb {
    margin-bottom: spacing(2);
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    gap: spacing(3);
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    font-size: 33px;
    line-height: 46px;
    @include up(tablet) {
      margin-bottom: spacing(6);
    }

    .btn {
      font-size: 14px;
      line-height: 20px;
      border-radius: 4px;
      padding: spacing(2) spacing(3);
    }
  }

  .body {
    padding: 0;
    margin-bottom: spacing(10);
    display: flex;
    flex-direction: row;
    gap: spacing(15);
    width: 100%;
    @include up(tablet) {
      padding: spacing(2);
    }

    .section {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      height: fit-content;
      margin: 0;
      width: inherit;

      .section_header {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        margin-bottom: spacing(4);
        gap: spacing(3);

        @include up(tablet) {
          flex-direction: row;
        }

        .content {
          flex-direction: row;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          @include up(tablet) {
            width: auto;
          }

          .section_title {
            line-height: 29px;
          }
        }
      }
    }
  }
}

@include up(tablet) {
  .main .breadcrumb {
    margin-bottom: spacing(6);
  }

  .main .header {
    font-size: 37px;
    line-height: 52px;

    .btn {
      padding: spacing(3) spacing(6);
      font-size: 16px;
      line-height: 24px;
      border-radius: 4px;
    }
  }
}

@include up(desktop) {
  .main .header {
    margin-bottom: spacing(8);

    .title {
      font-size: 45px;
      line-height: 59px;
    }
  }
}
