@import 'utils';


.title {
  color: var(--color-product-text);
  margin-bottom: spacing(4);
  text-transform: uppercase;
}

.default {
 padding: 0 spacing(6);
}

.info {
  padding: spacing(6);
  background-color: var(--color-polar);
}


