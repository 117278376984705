@import 'utils';

.wrapper {
  height: 20px;
  margin-bottom: spacing(4);
  margin-top: -8px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
  }
}

.list {
  display: flex;
  list-style: none;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 50px;
  padding-right: spacing(10);
}

.item {
  color: var(--color-product-text);
  white-space: nowrap;

  &:not(:last-child):after {
    color: var(--color-neutral-500);
    content: '/';
    padding: 0 spacing(3);
  }

  a {
    color: var(--color-brand-gartner-blue);
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}
