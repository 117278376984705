@import 'utils';

.skills_list {
  --gutter: 16px;
  --border-color: var(--interaction-03-default);
  display: flex;
  flex-wrap: wrap;
  gap: var(--gutter);
  list-style: none;
}

.skill {
  .skill_level {
    border-left: 1px solid var(--border-color);
    margin-left: var(--gutter);
    padding-left: var(--gutter);

    svg {
      color: var(--border-color);
      margin-bottom: 2px;
    }
  }
}

.group:not(:last-child) {
  margin-bottom: spacing(6);
}

.section_title {
  margin-bottom: spacing(4);
}
