@import 'utils';

.ambassador_page {
  display: flex;
  margin: 0 auto;
  max-width: 1275px;
  padding: 0 37.5px;

  .ambassador_page_roles {
    display: none;
  }

  .ambassador_page_profiles {
    @include flex-column;

    .roles_select {
      margin: 0 auto;
    }

    .pagination {
      margin: spacing(4) 0;
      padding: 0 spacing(2);
    }

    .cards_container {
      display: flex;
      flex-wrap: wrap;
      gap: spacing(6) spacing(9);
      justify-content: center;
    }
  }
}

@include up(tablet) {
  .ambassador_page {
    .ambassador_page_profiles {
      .roles_select {
        margin: unset;
      }

      .cards_container {
        justify-content: space-between;

        .card {
          flex-basis: 47%;
        }
      }
    }
  }
}

@include up(desktop) {
  .ambassador_page {
    .ambassador_page_roles {
      @include flex-column;
      gap: spacing(6);
      max-width: 225px;
      padding-top: spacing(4);
      width: 30%;

      .role_filters div {
        display: flex;

        label {
          cursor: pointer;
          display: flex;
          gap: spacing(2);
          padding: spacing(1) 0;
        }
      }

      .clear_all {
        color: var(--button-primary-bg-color);
        justify-content: center;
        padding: 10px;
      }
    }

    .ambassador_page_profiles {
      width: fit-content;

      .roles_select {
        display: none;
      }

      .cards_container {
        justify-content: center;

        .card {
          flex-basis: 0%;
          min-width: 300px;
        }
      }
    }
  }
}
