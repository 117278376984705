@import 'utils';

.bg_wrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 175px;
  overflow: hidden;
  position: relative;
  z-index: -1;

  .bg_img {
    height: 100% !important;
    inset: unset !important;
    object-fit: cover;
    object-position: right top;
    width: 100% !important;
  }

  @include up(tablet) {
    height: 400px;
    margin: 1px 0;
    position: absolute;
    width: 100%;
    .bg_img {
      inset: 0 !important;
    }
  }
}

.banner {
  padding: 0 20px !important;

  .banner_card {
    align-items: flex-start;
    background: var(--neutrals-01-white);
    border-left: 16px solid var(--color-brand-tangerine);
    border-right: 16px solid var(--color-brand-tangerine);
    display: flex;
    flex-direction: column;
    gap: spacing(6);
    justify-content: center;
    margin-top: -20px;
    padding: 18px;

    .banner_msg {
      color: var(--color-product-text);
      line-height: 130%;
    }
  }

  @include up(tablet) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: center;
    padding: 60px;
    .banner_card {
      align-items: flex-start;
      display: flex;
      gap: spacing(6);
      margin: 0;
      padding: 52px spacing(9);
      width: max(50%, 650px);

      .banner_msg {
        font-size: 29px;
      }
    }
  }
}
