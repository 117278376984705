@import 'utils';

.modal_header {
  align-items: center;
  display: flex;
  gap: spacing(2);
}

.modal_content {
  @include flex-column;
  display: flex;
  gap: spacing(4);
  padding: spacing(5);
}

.modal_footer {
  display: flex;
  justify-content: flex-end;
}
