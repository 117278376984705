@import 'utils';

.select {
  align-items: center;
  display: flex;
  position: relative;

  select {
    background-color: transparent;
    color: transparent;
    height: min-content;
    line-height: inherit;
    margin: 0;
    max-height: 33px;
    padding: spacing(2) spacing(3);
    width: 100%;
    z-index: 1;

    &:focus {
      outline: none;
    }

    option {
      background-color: var(--color-background-hex); // this is to solve colors in some computers
      color: var(--color-product-text); // this is to solve colors in some computers
    }
  }
}

.dropdown_icon {
  margin-right: spacing(2);
  position: absolute;
  right: 0;
  z-index: 0;
}

.text {
  margin-left: spacing(2);
  position: absolute;
  z-index: 0;
}

.placeholder {
  color: var(--color-neutral-500);
}
