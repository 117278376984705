@import 'utils';

.container {
  display: contents;
}

.card {
  @include flex-column;
  flex-direction: column-reverse;
  padding: spacing(6);
  gap: spacing(4);

  @include up(desktop) {
    @include flex-row;
    align-items: center;
    gap: spacing(10);
  }
}

.card_text {
  @include flex-column;
  gap: spacing(4);
  align-items: flex-start;
  @include up(desktop) {
    max-width: 50%;
  }
}

.image {
  width: 100%;
  height: 100%;
  position: relative;
  aspect-ratio: 600/180;
  img {
    object-fit: cover;
  }

  @include up(desktop) {
    aspect-ratio: auto;
  }
}

.year,
.share_button {
  display: none;
  @include up(desktop) {
    display: flex;
  }
}

.share_button {
  gap: spacing(1);
}
