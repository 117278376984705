@import 'utils';

.filter_wrapper {
  margin-top: spacing(8);
  @include up(desktop) {
    display: flex;
  }
}

.filters_title {
  margin: 0 spacing(2) spacing(2) 0;
  text-wrap: nowrap;
}

.filter_item {
  display: inline-block;
  margin: spacing(1);

  &:hover {
    cursor: pointer;
  }

  &.hidden_mobile {
    @include down(tablet) {
      display: none;
    }
  }
}

.mobile_show_more {
  color: var(--color-utility-interaction-500);
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }

  @include up(desktop) {
    display: none;
  }
}
