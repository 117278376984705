@import 'utils';

.footer {
  @include flex-column;

  gap: spacing(8);

  margin: 0 auto;
  max-width: 1440px;
  padding: spacing(6) spacing(5);

  @include full_bleed(#f4f4f4);
  color: var(--color-product-text);

  @include up(tablet) {
    padding: spacing(8) spacing(15);
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.social_icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: spacing(2);

  a {
    height: 32px;
    width: 32px;
  }
}

.nav {
  display: flex;
  flex-direction: column;

  a {
    padding: 0 0 spacing(3) 0;
    margin: 0 0 spacing(3) 0;
    border-width: 0 0 1px 0;
    border-color: var(--color-neutral-400);
    border-style: solid;
  }

  @include up(tablet) {
    flex-direction: row;
    flex-wrap: wrap;
    a {
      border-width: 0 0 0 1px;
      margin: 0 0 0 spacing(2);
      padding: 0 0 0 spacing(2);
      &:first-child {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}
