@import 'utils';

.testimonial {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 2;

  padding: spacing(3);

  .testimonials_text {
    font-style: italic;
    color: var(--color-product-text);
    margin-bottom: spacing(6);
  }

  .testimonial_profile {
    display: flex;
    align-items: flex-start;
    gap: spacing(6);
    .testimonial_profile_text {
      padding-left: spacing(6);
      border-left: 3px solid var(--color-brand-tangerine);
      &.without_avatar {
        margin-left: spacing(15);
      }
    }
  }
}
