@import 'utils';

.article_section {
  background-color: var(--color-background-hex);

  padding: spacing(5);
  img {
    border-radius: 3px;
  }
  @include up(desktop) {
    padding: 60px;
  }

  &:nth-child(odd) {
    background-color: var(--color-neutral-400);
  }
}

.section_heading {
  color: var(--color-brand-gartner-blue);
}

.chart {
  .chart_caption {
    text-align: center;
  }

  .chart_image {
    height: auto;
    width: 100%;
  }

  &.split {
    @include up(desktop) {
      .content {
        display: flex;
        gap: spacing(10);

        > div {
          flex: 1;
        }
      }
    }
  }
}
