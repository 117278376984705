@import 'utils';

.card {
  box-shadow: var(--box-shadow-01-normal, 0px 0px 24px 0px #e8e8e8);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: var(--box-shadow-01-raised, 0px 3px 24px 0px #c3c3c3);
  }

  a {
    @include flex-column;
    align-items: flex-start;
    gap: spacing(3);
    min-height: 100%;
    padding: spacing(6);
  }
}

.card_image {
  margin-bottom: spacing(3);
  width: 100%;

  img {
    height: auto;
    width: 100%;
  }

  .highlighted_image {
    margin-left: -2px;
    width: calc(100% + 4px);
  }
}

.card_link {
  margin-top: auto;
}

.card_content {
  margin-bottom: spacing(6);

  > p {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    margin-bottom: spacing(3);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
