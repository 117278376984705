@import 'utils';

.ambassador_card {
  background: var(--neutrals-01-white);
  box-shadow: var(--box-shadow-01-normal);
  display: inline-flex;
  flex-direction: column;
  gap: spacing(4);
  padding: spacing(6);
  transition: box-shadow 0.3s ease-in-out,
  transform 0.3s ease-in-out;
  width: 100%;

  &:hover {
    box-shadow: var(--box-shadow-01-raised);
    transform: scale(1.01);
  }

  .ambassador_card_img {
    display: flex;
    justify-content: center;

    .img {
      height: 250px;
      width: 250px;

      span,
      img {
        border-radius: 0;
        font-size: 120px;
      }
    }
  }

  .info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    min-height: 130px;

    .badge {
      font-size: 12px;
      line-height: 120%;
    }

    .name {
      font-size: 18px;
      line-height: 29px;
    }
  }
}

@include up(tablet) {
  .ambassador_card {
    .info {
      .name {
        font-size: 24px;
        line-height: 36px;
      }

      .title {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@include up(desktop) {
  .ambassador_card {
  }
}
