@import 'utils';

.container {
  display: none;

  .text_container {
    display: flex;
    gap: spacing(1);
    align-items: center;
    justify-content: flex-end;
  }

  .success_icon {
    color: var(--color-neutral-600);
  }

  .connected_text {
    font-size: 14px;
    line-height: 24px;
  }

  .email {
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    color: var(--color-utility-interaction-500);
  }
}

@include up(tablet) {
  .container {
    display: flex;
    flex-direction: column;
    gap: spacing(1);
    align-items: flex-end;
  }
}
