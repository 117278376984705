@import 'utils';

.pending_input {
  align-items: center;
  border: 1px solid var(--color-neutral-500);
  display: inline-flex;
  margin-left: 2px;
  padding-right: spacing(2);
  position: relative;

  &:focus-within {
    border-color: var(--color-utility-interaction-500);
    outline: 2px solid var(--color-utility-interaction-50);
  }
}

.input {
  border: none;
  flex: 1;
  padding: spacing(2);

  &:focus {
    outline: none;
  }
}
