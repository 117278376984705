.apps {
  column-gap: spacing(3);
  display: flex;
  margin-top: spacing(4);

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.store_logos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;

  a {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  img {
    max-width: 130px;
  }
}

.qr_code {
  border: 1px solid var(--color-neutral-500);
  width: 40%;
}
