@import 'utils';

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-graphik);
  font-weight: 400;
}

.paragraph,
.subheading,
.disclaimer,
.plain,
.cta {
  font-weight: 400;
}

.h1 {
  font-size: 33px;
  line-height: 40px;
}

.h2 {
  font-size: 27px;
  line-height: 33px;
}

.h3 {
  font-size: 23px;
  line-height: 28px;
}

.h4 {
  font-size: 19px;
  line-height: 23px;
}

.h5 {
  font-size: 16px;
  line-height: 20px;
}

.h6 {
  font-size: 14px;
  line-height: 20px;
}

.h7 {
  font-size: 12px;
  line-height: 15px;
}

.paragraph {
  font-size: 16px;
  line-height: 24px;
}

.paragraph.small {
  font-size: 14px;
  line-height: 20px;
}

.subheading {
  font-size: 22px;
  line-height: 28px;
  text-transform: capitalize;
}

.subheading.small {
  font-size: 20px;
  line-height: 28px;
}

.cta {
  color: var(--color-utility-interaction-500);
  font-size: 16px;
  line-height: 18px;
}

.cta.small {
  font-size: 14px;
  line-height: 16px;
}

.cta.xsmall {
  font-size: 12px;
  line-height: 14px;
}

.disclaimer {
  font-size: 12px;
  line-height: 16px;
}

.plain {
  font-size: 16px;
  line-height: 18px;
}

.plain.small {
  font-size: 14px;
  line-height: 16px;
}

.tag.xsmall {
  font-size: 12px;
  letter-spacing: 0.36px;
  line-height: 17px;
}

/* Replace for mixin later */
@include up(tablet) {
  .h1 {
    font-size: 39px;
    line-height: 47px;
  }

  .h2 {
    font-size: 33px;
    line-height: 40px;
  }

  .h3 {
    font-size: 27px;
    line-height: 33px;
  }

  .h4 {
    font-size: 23px;
    line-height: 28px;
  }

  .h5 {
    font-size: 19px;
    line-height: 24px;
  }

  .h6 {
    font-size: 16px;
    line-height: 24px;
  }

  .h7 {
    font-size: 14px;
    line-height: 17px;
  }
}
