@import 'utils';

.container {
  display: flex;
  gap: spacing(3);
  align-items: center;

  .button {
    padding: spacing(2) spacing(3);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
    min-width: 100px;

    &:active:not(.disabled) {
      border: 0;
    }
  }
}

@include up(tablet) {
  .container {
    flex-flow: row wrap;

    .button {
      padding: spacing(3) spacing(6);
      line-height: 24px;
      font-size: 16px;
    }
  }
}
