@import 'utils';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: spacing(3);

  .count_text {
    font-size: 19px;
    line-height: 29px;
  }
}
