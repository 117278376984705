@import 'utils';

.slider_buttons {
  margin-top: spacing(4);
  text-align: center;
}

.button {
  margin-bottom: 2px;
  vertical-align: middle;
  color: var(--color-utility-interaction-500);

  &:disabled {
    border: 0;
    color: var(--color-neutral-500);
  }
}
