@import 'utils';

.close_button {
  position: absolute;
  top: spacing(6);
  right: spacing(6);

  svg {
    color: var(--color-black);
  }
}