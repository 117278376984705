@import 'utils';

.card {
  background-color: var(--neutrals-01-white);
  border-radius: 4px;
  box-shadow: var(--box-shadow-01-normal);
  margin-bottom: spacing(6);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: var(--box-shadow-01-raised);
  }
}
