@import 'utils';

.multiselect {
  background-color: grey;
  position: relative;

  input[type='text'] {
    border: 1px solid var(--color-neutral-600);
    border-radius: 0;
    height: min-content;
    padding: spacing(2) spacing(3);
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}

.select_box {
  select {
    border: 1px solid var(--color-neutral-600);
    border-radius: 0;
    color: transparent;
    height: min-content;
    max-height: 33px;
    padding: spacing(2) spacing(3);
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}

.select_box {
  align-items: center;
  display: flex;
  position: relative;
}

.expanded {
  background: var(--color-background-hex);
  box-shadow: var(--box-shadow-03-normal);
  height: min-content;
  position: relative;
  position: absolute;
  visibility: hidden;
  width: 100%;
  z-index: 2;
}

.expanded_open {
  visibility: visible;
}

.checkboxes {
  display: grid;
  max-height: 150px;
  overflow-y: auto;
  padding: spacing(5);
  row-gap: spacing(3);
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: spacing(1);
  width: 100%;
}

.text {
  margin-left: spacing(2);
  position: absolute;
}

.placeholder {
  color: var(--color-neutral-500);
}

.dropdown_icon {
  margin-right: spacing(2);
  position: absolute;
  right: 0;
}
