@import 'utils';

.active_ambassadors {
  @include flex-column;
  .view_ambassadors {
    color: var(--color-utility-interaction-500);
    padding: spacing(6);
    margin: 0 auto;
  }

  .cards {
    .ambassador_card {
      @include flex-row;
      gap: spacing(6);
      @include up(desktop) {
        @include flex-column;
        height: 100%;
      }
      .avatar {
        flex-shrink: 0;
        height: 58px;
        width: 58px;
        @include up(desktop) {
          height: 110px;
          width: 110px;
          border-width: 4px;
          span {
            font-size: 48px;
          }
          .avatar_badge {
            width: 28px;
            height: 28px;
            right: 0;
          }
        }
      }

      .content {
        @include flex-column;
        height: 100%;
        gap: spacing(4);
        align-items: flex-start;
        .country {
          margin-top: auto;
        }
      }
    }

    .ambassador_title {
      @include up(desktop) {
        font-weight: bold;
      }
    }
  }
}

.cards {
  @include flex-column;
  @include up(desktop) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  gap: spacing(6);
}
