@import 'utils';

.modal {
  @include flex-column;
  display: flex;
  flex-direction: column;
  gap: spacing(3);
  height: 70%;
  @include up(desktop) {
    width: 50%;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: spacing(3);
  overflow: hidden;
  padding: 0px 32px;
}

.footer {
  align-items: center;
  background-color: var(--color-neutral-300);
  border-top: 1px solid var(--color-neutral-400);
  display: flex;
  gap: spacing(3);
  justify-content: flex-end;
  margin-top: auto;
  padding: spacing(3);
}

.description {
  display: inline-block;
  margin: spacing(2) 0;
}

.error {
  margin-top: spacing(4);
}
