@import 'utils';

.wrapper {
--header-height: 80px;
min-height: calc(100vh - var(--header-height)); // TODO: Explore other way of achieving this effect. 80px is the height of the header.

  display: flex;
  flex-direction: column;

  .main {
    padding: 0 10px;
    margin: spacing(10) auto;
    max-width: 1440px;
    width: 100%;

    .breadcrumb {
      margin-bottom: spacing(2);
    }

    .header {
      display: flex;
      flex-wrap: wrap;
      gap: spacing(3);
      align-items: center;
      justify-content: space-between;
      margin-bottom: spacing(6);

      .btn {
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        padding: spacing(2) spacing(3);
      }
    }

    .title {
      font-size: 33px;
      line-height: 46px;
    }
  }

  .footer {
    margin-top: auto;
    width: 100%;
  }
}

@include up(tablet) {
  .wrapper .main .breadcrumb {
    margin-bottom: spacing(6);
  }

  .wrapper .main .header .title {
    font-size: 37px;
    line-height: 52px;
  }

  .wrapper .main .header .btn {
    padding: spacing(3) spacing(6);
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
  }
}

@include up(desktop) {
  .wrapper .main .header {
    margin-bottom: spacing(8);

    .title {
      font-size: 45px;
      line-height: 59px;
    }
  }
}
