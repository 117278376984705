@import 'utils';

.departments_selector {
  align-items: center;
  border: 1px solid var(--color-neutral-500);
  border-radius: 4px;
  color: var(--color-product-text);
  display: flex;
  gap: spacing(4);
  justify-content: space-between;
  min-height: 38px;
  min-width: 150px;
  padding: spacing(2);

  &:hover {
    color: var(--color-product-text);
  }
}
