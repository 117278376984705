@import 'utils';

.background > section,
.container > section {
  padding: spacing(9) spacing(5);
  @include up(tablet) {
    padding: spacing(15);
  }
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.sections {
  > .section:nth-child(even) {
    background-color: var(--color-cultured-white);
  }
  .section {
    section {
      padding: spacing(9) spacing(5);
      @include up(tablet) {
        padding: 60px;
      }
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
    }
  }
}

.container .section {
  .slider {
    grid-template-columns: repeat(6, 100%);
    @include up(desktop) {
      grid-template-columns: repeat(6, 33%);
    }
  }
}
