@import 'utils';

.topics_section {
  @include flex-column;
  gap: spacing(3);

  .topics_selected {
    font-weight: 500;
  }

  .topics_selection_section {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    gap: spacing(3);

    .topics {
      display: flex;
      gap: spacing(3);
    }

    .add_topic {
      color: var(--color-utility-interaction-500);
    }
  }
}
