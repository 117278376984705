@import 'utils';

.radio_button {
  align-items: center;
  display: flex;
  gap: spacing(2);

  input,
  label {
    cursor: pointer;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--color-background-hex);
    border: 0.15em solid var(--color-neutral-500);

    border-radius: 50%;
    display: grid;
    height: 20px;
    place-content: center;
    width: 20px;
  }

  input[type='radio']::before {
    border-radius: 50%;
    box-shadow: inset 1em 1em var(--color-utility-interaction-500);
    content: '';
    height: 10px;

    transform: scale(0);
    transition: 120ms transform ease-in-out;
    width: 10px;
  }

  input[type='radio']:checked {
    border: 0.15em solid var(--color-utility-interaction-500);
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
}
