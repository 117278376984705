@import 'utils';

.pagination {
  li {
    display: inline-block;
    padding-bottom: spacing(2);

    &:not(:last-child) {
      &:after {
        color: var(--color-neutral-500);
        content: '/';
        padding: 0 spacing(3);
      }
    }
  }

  a {
    color: var(--color-brand-gartner-blue);
  }
}

.active {
  font-weight: 500;
}
