@import 'utils';

.checkbox {
  --size: 16px;
  appearance: none;
  background-size: cover;
  border: 2px solid var(--color-neutral-600);
  flex-shrink: 0;
  height: var(--size);
  width: var(--size);

  &:not(:disabled):hover,
  &:not(:disabled):checked,
  &:focus-visible,
  &:indeterminate {
    border-color: var(--color-utility-interaction-500);
  }

  &:not(:disabled):hover {
    cursor: pointer;
  }

  &:checked {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 12 10" xml:space="preserve"><path style="fill: white" d="m9.93 2.339.108-.128-.128-.107-.511-.428-.128-.107-.107.127-4.07 4.85-2.362-1.98-.128-.107-.107.127-.428.51-.107.128.127.107 3 2.518.128.108.107-.128 4.607-5.49Z"/></svg>');
  }

  &:not(:disabled):checked,
  &:indeterminate {
    background-color: var(--color-utility-interaction-500);
  }

  &:disabled {
    background-color: var(--color-neutral-300);
  }

  &:disabled:checked {
    background-color: var(--color-neutral-500);
    border-color: var(--color-neutral-500);
  }

  &:focus-visible {
    outline-color: var(--color-utility-interaction-200);
  }

  &:indeterminate {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 12 10" xml:space="preserve"><rect x="1" y="4" width="10" height="2" fill="white"/></svg>');
  }
}
