@import 'utils';

.error {
  margin: 0 auto;
  max-width: 1000px;

  .img {
    display: block;
    margin: 0 auto;
  }

  .message {
    color: var(--color-neutral-500);
    text-align: center;
  }
}
