@import 'utils';

.container {
  display: flex;
  flex-direction: column;
  gap: spacing(4);
}

.checkbox_with_label {
  align-items: center;
  display: flex;
  gap: spacing(2);
}

.inputs {
  display: grid;
  grid-template-columns: 100%;
  row-gap: spacing(6);

  @include up(desktop) {
    column-gap: spacing(6);
    grid-template-columns: calc(50% - spacing(3)) calc(50% - spacing(3));
  }
}
