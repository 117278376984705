@import 'utils';

.container {
  display: flex;
  flex-direction: column;
  gap: spacing(7);
}

.checkbox_with_label {
  align-items: center;
  display: flex;
  gap: spacing(2);
}

.inputs {
  display: flex;
  flex-direction: column;
  grid-template-columns: 100%;
  row-gap: spacing(6);
}
