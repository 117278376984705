@import 'utils';

.avatar {
  @include up(tablet) {
    // Inner white "border"
    background-clip: content-box;
    padding: 6px;
  }
}

.badge {
  border-radius: 4px;
  font-weight: 500;
  padding: spacing(1) spacing(2);
  text-transform: uppercase;
}

.user_info {
  --gutter: 24px;

  .avatar {
    left: -18px;
    position: relative;
    top: calc(calc(var(--gutter) * -1) + -80px);
  }

  .name {
    color: var(--section-heading-color);
    font-size: 48px;
    margin-bottom: var(--gutter);
  }

  .content {
    margin-top: -84px;
  }

  .title {
    font-size: 19px;
  }

  .title,
  .location,
  .verified_ambassador,
  .connections {
    margin-bottom: spacing(4);
  }

  .socials {
    color: var(--interaction-02-default);
    display: flex;
    gap: spacing(3);
  }

  &.ambassador {
    .badge {
      background-color: var(--color-badge);
      color: var(--color-black); // This doesn't change in dark mode
      display: inline-block;
      margin-bottom: var(--gutter);
    }
  }

  .edit_profile {
    --color: var(--color-utility-interaction-500);
    border: 2px solid var(--color);
    color: var(--color);
    display: flex;
    gap: 6px;
    margin-bottom: spacing(6);
    padding: spacing(3) spacing(4);
    width: fit-content;

    .edit_profile_icon {
      display: none;
    }
  }

  // When in desktop, move button to the rop right corner of the section
  @include up(desktop) {
    position: relative;
    .edit_profile {
      border: none;
      margin: 0;
      padding: 0;
      position: absolute;
      right: var(--gutter);
      top: var(--gutter);

      .edit_profile_icon {
        display: unset;
      }
    }
  }
}

.connect_button {
  margin-top: spacing(4);
}
