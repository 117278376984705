@import 'utils';

.wrapper {
  align-items: center;
  background: var(--color-cultured-white);
  display: flex;
  flex-direction: column;
  gap: spacing(13);
  padding: spacing(9) spacing(5);

  @include up(desktop) {
    gap: spacing(10);
    padding: spacing(15) spacing(5);
  }
}

.title {
  @include up(desktop) {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: spacing(3);
  }
}

.cards {
  display: flex;
  flex-direction: column;
  gap: spacing(6);
  max-width: 1080px;
  @include up(desktop) {
    flex-direction: row;
  }
}

.card {
  display: flex;
  gap: spacing(8);
  @include up(desktop) {
    flex-direction: column;
    max-width: 270px;
    padding: spacing(6);
  }
}

.card_text {
  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    color: var(--color-product-text);
  }
}
