@import 'utils';

.user_content {
  .title {
    margin: spacing(8) 0 spacing(6) 0;
  }

  .anonymous_card > div {
    border: none;
    box-shadow: none;
  }
}
