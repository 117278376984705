@import 'utils';

.wrapper {
  @include flex_column;
  align-items: center;
  gap: spacing(5);
}

.subheading {
  padding: spacing(2) 0 spacing(10) 0;
}

.cards_title {
  width: 100%;
}

.cards {
  display: grid;
  gap: spacing(9);
  grid-template-columns: auto;

  @include up(tablet) {
    gap: spacing(9) spacing(15);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include up(desktop) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.view_all {
  align-self: center;
  color: var(--color-utility-interaction-500);
  font-weight: 500;
  margin-top: 30px;
  width: fit-content;
}
