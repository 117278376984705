@import 'utils';

.card {
  img {
    display: block;
    height: auto;
    width: 100%;
  }

  a {
    color: var(--color-utility-interaction-500);

    svg {
      padding-left: spacing(1);
      vertical-align: middle;
    }
  }

  .card_title {
    padding: spacing(4) 0;
  }
}
