@import 'utils';

.main {
  margin: spacing(10) auto 0 auto;
  max-width: 1440px;
  padding: 0 spacing(4);
  @include up(tablet) {
    margin: spacing(10) auto spacing(20) auto;
    padding: 0 60px;
  }
}
