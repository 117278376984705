@import 'utils';

.search {
  background-color: var(--neutrals-01-white);
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  padding: spacing(3);
  border-bottom: 3px solid var(--color-neutral-500);
  z-index: 5;
}

.search_input {
  background-color: var(--color-background-hex);
  color: var(--color-neutral-600);
  flex: 1;
  border: none;
  padding: spacing(3) 0;
  font-size: 20px;
  &:focus {
    outline: none;
  }
}

.search_form {
  margin: 0 auto;
  max-width: 900px;
}

.search_control {
  display: flex;
  border-bottom: 2px solid var(--color-neutral-500);
  .submit_btn {
    background: unset;
    border: unset;
    color: var(--color-neutral-600);
  }
}
