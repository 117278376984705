@import 'utils';

.title {
  color: var(--color-product-text);
  margin-bottom: spacing(4);
  text-transform: uppercase;
}

.list {
  list-style: none;
  color: var(--color-product-text);
  li {
    margin-bottom: spacing(6);
  }
}

.card_link {
  display: flex;
  padding: 6px 0;
  color: var(--color-utility-interaction-500);
}
