@import 'utils';

.container {
  display: block;
  border: 1px solid var(--color-neutral-500);
  margin: 0;
  padding: spacing(2);
  resize: none;
  white-space: pre-wrap;
  width: 100%;
  font-size: 16px;

  textarea {
    border: 0;
    padding: 0;
    outline: 0;
    width: 100%;
    font-size: 16px;
  }
}
