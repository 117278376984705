@import 'utils';

.form {
  display: flex;
  gap: spacing(2);

  .input {
    flex: 1;
  }
}
