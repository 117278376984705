@import 'utils';

.join_banner {
  background: var(--color-brand-gartner-blue);
  display: flex;
  flex-direction: column;
  gap: spacing(6);
  justify-content: center;
  max-width: 100% !important;
  min-height: 200px;
  padding: spacing(9) spacing(5);
  text-align: center;

  .join_banner_title {
    color: var(--color-background-hex);
  }

  .join_banner_btn button,
  .join_banner_btn a {
    border: none;
    margin: 0 auto;
  }
}
