@import 'utils';

.insights {
  background-color: var(--color-polar);
  padding-bottom: 18px;
  margin: 0 -25px;
  .title {
    text-align: center;
    @include up(tablet) {
      text-align: left;
    }
  }

  .omi_heading {
    font-weight: 400;
    background-color: var(--color-brand-gartner-blue);
    color: var(--color-background-hex);
  }
}

.insight_list {
  list-style: none;
  .insight:not(:last-child) {
    margin-bottom: spacing(5);
  }
}
