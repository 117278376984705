@import 'utils';

.primary,
.secondary,
.ghost {
  align-items: center;
  color: var(--color-white);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  height: fit-content;
  justify-content: center;
  line-height: 100%;
  text-align: center;
  width: fit-content;
}

.primary {
  background-color: var(--button-primary-bg-color);
  border: 2px solid var(--button-primary-bg-color);
}

.primary:hover {
  background-color: var(--color-utility-interaction-600);
  border: 2px solid var(--color-utility-interaction-600);
}

.primary:active:not(.disabled) {
  background-color: var(--color-utility-interaction-700);
  border: 2px solid var(--color-utility-interaction-700);
}

.primary:disabled {
  background-color: var(--color-neutral-400);
  border: 2px solid var(--color-neutral-400);
}

.primary:disabled:active {
  background-color: var(--color-neutral-400);
  border: 2px solid var(--color-neutral-400);
}

.secondary {
  background-color: var(--color-background-hex);
  border: 2px solid var(--color-utility-interaction-500);
  color: var(--color-utility-interaction-500);
  padding: spacing(2) spacing(3);
}

.secondary:hover {
  border: 2px solid var(--color-utility-interaction-600);
  color: var(--color-utility-interaction-600);
}

.secondary:active {
  border: 2px solid var(--color-utility-interaction-700);
  color: var(--color-utility-interaction-700);
}

.secondary:disabled {
  border: 2px solid var(--color-neutral-500);
}

.secondary:disabled:active {
  border: 2px solid var(--color-neutral-500);
  color: var(--color-neutral-500);
}

.regular {
  gap: spacing(2);
  padding: spacing(3) spacing(4);
}

.small {
  gap: 6px;
  padding: spacing(2) spacing(5);
}

.ghost {
  background: none;
  border: 0;
  color: inherit;
  color: var(--button-primary-bg-color);
  cursor: pointer;
  display: inline-flex;
  font-weight: 400;
  padding: 0;
}

.ghost + .small {
  font-size: 14px;
}

.ghost:hover {
  color: var(--color-utility-interaction-600);
}

.ghost:active {
  color: var(--color-utility-interaction-700);
}

.primary,
.secondary,
.ghost {
  &:disabled,
  &.disabled {
    color: var(--interaction-02-default);
    cursor: default;
  }
}
