.error {
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  max-width: 1000px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;

  .message {
    color: var(--brand-gartner-blue);
    font-size: 18px;
    font-weight: bold;
    width: 450px;
  }
}
