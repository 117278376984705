@import 'utils';

.content_card {
  background-color: var(--neutrals-01-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-01-normal);
  display: grid;
  grid: "header header" auto
  "main main" auto
  "tags tags" auto
  "poll poll" auto
  "footer footer" auto
  "footer footer" auto / 1fr 1fr;
  margin-block-end: spacing(6);
  padding-inline: spacing(6);
  transition: box-shadow 0.3s ease-in-out;

  @include up(desktop) {
    grid: "header header" auto
  "main main" auto
  "tags tags" auto
  "poll poll" auto
  "footer footer" auto / 1fr 1fr;
  }

  &:hover {
    box-shadow: var(--box-shadow-01-raised);
  }

  .content_title {
    color: var(--color-product-text);
    font-size: 22px;
    grid-area: main;
    line-height: 32px;
    position: relative;
    word-break: break-word;
    z-index: 2;
  }

  .card_poll {
    padding-bottom: spacing(4);
    @include up(desktop) {
      padding: 0 spacing(6) spacing(4) spacing(6);
    }
  }
}

.footer {
  border-top: 1px solid var(--color-neutral-300);
  display: grid;
  gap: spacing(4) 0;
  grid-area: footer;
  grid-template-columns: 100%;
  grid-template-rows: subgrid;
  padding-block: spacing(6);

  @include up(desktop) {
    gap: 0;
    grid-template-columns: auto;
  }
}
