@import 'utils';

.what_sets_us_apart {
  @include flex-column;
  align-items: flex-start;
  background-color: var(--color-polar);
  gap: spacing(4);
  padding: spacing(6);

  p {
    color: var(--color-product-text);
    line-height: 1.5;
    margin: 0;
  }
}

.title {
  margin: 0;
  text-transform: uppercase;
}

.gray {
  color: var(--color-product-text);
}
