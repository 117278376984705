@import 'utils';

.highlight_cards {
  max-width: 1080px;

  .highlight_card:not(:last-of-type) {
    margin-bottom: spacing(8);
  }

  @include up(desktop) {
    @include flex-row;
    gap: spacing(8);
    .highlight_card:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
}

.highlight_card {
  @include flex-row;
  color: var(--color-product-text);
  gap: spacing(10);
  text-align: left;

  svg {
    color: var(--color-picton-blue);
    min-width: 60px;
  }

  .title {
    margin-bottom: spacing(4);
  }

  @include up(desktop) {
    display: block;
    text-align: center;
    svg {
      margin-bottom: spacing(4);
    }
  }
}
