@import 'utils';

.content_might {
  color: var(--color-product-text);
  font-size: 24px;
  line-height: 32px;
  margin: spacing(20) spacing(4) spacing(10);
  @include up(tablet) {
    margin: spacing(30) 0 spacing(10);
  }
}

.breadcrumb {
  padding: 0 spacing(5);
  @include up(desktop) {
    padding: 0;
  }
}

.content {
  max-width: 100%;
  @include up(tablet) {
    margin: 0 auto 0 15px;
    width: calc(100% - 320px);
  }
  @include up(desktop) {
    margin-right: spacing(11);
  }
}

.side_bar {
  display: none;
  @include up(tablet) {
    display: block;
    height: calc(100vh - 100px);
    margin: 0 15px auto 15px;
    max-width: 320px;
    -ms-overflow-style: none;
    overflow-y: auto;
    position: sticky;
    scrollbar-width: none;
    top: 100px;
    z-index: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

