@import 'utils';

.container {
  flex-flow: row wrap;
  display: flex;
  align-items: center;
  gap: spacing(2);

  .pending_text {
    color: var(--color-neutral-800);
  }

  .button {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: spacing(2) spacing(3);
  }
}

@include up(tablet) {
  .container {
    flex-direction: column;

    .button {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
