@import 'utils';

.communities {
  --gap: 32px;
  --num-columns: 1;
  display: grid;
  gap: var(--gap) 0;
  grid-template-columns: repeat(auto-fit, minmax(calc(100% / var(--num-columns)), 1fr));
  list-style: none;

  @include up(tablet) {
    --num-columns: 2;
  }
  @include up(desktop) {
    --num-columns: 4;
  }
}

.community_item {
  color: var(--color-neutral-700);

  a {
    font-size: var(--font-size);
    --font-size: 14px;

    @include up(desktop) {
      --font-size: 19px;
    }
  }
}

.community_title {
  margin-block-end: 50px;
  text-align: center;
}